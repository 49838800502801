/* work-sans-100normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src:
    local('Work Sans Thin '),
    local('Work Sans-Thin'),
    url('./files/work-sans-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-100.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-200normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src:
    local('Work Sans Extra Light '),
    local('Work Sans-Extra Light'),
    url('./files/work-sans-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-200.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-300normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src:
    local('Work Sans Light '),
    local('Work Sans-Light'),
    url('./files/work-sans-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-300.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-400normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Work Sans Regular '),
    local('Work Sans-Regular'),
    url('./files/work-sans-latin-400.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-400.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-500normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    local('Work Sans Medium '),
    local('Work Sans-Medium'),
    url('./files/work-sans-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-500.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-600normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    local('Work Sans SemiBold '),
    local('Work Sans-SemiBold'),
    url('./files/work-sans-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-600.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-700normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    local('Work Sans Bold '),
    local('Work Sans-Bold'),
    url('./files/work-sans-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-700.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-800normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    local('Work Sans ExtraBold '),
    local('Work Sans-ExtraBold'),
    url('./files/work-sans-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-800.woff') format('woff'); /* Modern Browsers */
}

/* work-sans-900normal - latin */
@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src:
    local('Work Sans Black '),
    local('Work Sans-Black'),
    url('./files/work-sans-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
    url('./files/work-sans-latin-900.woff') format('woff'); /* Modern Browsers */
}

